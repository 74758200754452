@import url("https://fonts.googleapis.com/css2?family=Archivo:wght@400;700&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "Archivo";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Archivo";
}

* {
  font-family: "Archivo";
  line-height: 1.5;
}

.sidePhone {
  height: 200px;
  object-fit: contain;
}

.mainPhone {
  height: 300px;
  object-fit: contain;
  margin: 0px 20px;
}

.floating {
  animation: float 5s infinite;
  position: relative;
}

@keyframes float {
  0% {
    bottom: 0px;
  }
  50% {
    bottom: 30px;
  }
  100% {
    bottom: 0px;
  }
}

.button:hover {
  cursor: pointer;
}

.slick-prev:before,
.slick-next:before {
  color: yellow;
}

.slick-dots li button:before {
  background-color: #fff;
  height: 5px !important;
  width: 25px !important;
  border-radius: 20px;
  overflow: hidden;
}

.slick-dots li.slick-active button:before {
  background-color: #ffff;
  height: 5px;
  width: 25px;
  border-radius: 20px;
  overflow: hidden;
}

.dots li button:before {
  background-color: #000;
  height: 5px !important;
  width: 25px !important;
  border-radius: 20px;
  overflow: hidden;
}

.dots li.slick-active button:before {
  background-color: #000;
  height: 5px !important;
  width: 25px !important;
  border-radius: 20px;
  overflow: hidden;
}
